import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Search, Typography, Chip, MultiSelect } from "@equinor/eds-core-react";

const List = styled.ul`
  position: relative;
  list-style: none;
  padding: 0px;
  margin: 0px;
  ${({ embedded }) => {
    if (embedded) {
      return `height: calc(100vh - 340px);
        overflow-y: scroll;`;
    }
    return "";
  }}
  margin-top: 32px;

  & > * {
    text-decoration: none;
  }
`;

const ListItem = styled.li`
  width: auto;
  position: relative;
  margin: 0px;
  color: var(--eds_text__static_icons__default, rgba(61, 61, 61, 1));
  font-family: Equinor;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 0.013em;
  text-align: left;
  padding: 16px 24px;
  border-bottom: 2px solid
    var(--eds_ui_background__light, rgba(247, 247, 247, 1));

  &:hover {
    cursor: pointer;
    background: var(--eds_ui_background__light, rgba(247, 247, 247, 1));
  }
`;

const ContentDivider = styled.div`
  display: flex;
  direction: row;

  & > *:first-child {
    margin-left: 0;
  }

  & > * {
    margin-left: 24px;
  }
`;

const plantTypeVariants = [
  "Fast innretning",
  "Flyttbar innretning",
  "Landanlegg/andre",
];

const PlantList = ({ embedded = false, plantList }) => {
  const [plants, setPlants] = useState(plantList);
  const [searchValue, setSearchValue] = useState("");
  const [selectedPlantTypes, setSelectedPlantTypes] = useState([]);

  useEffect(() => {
    if (searchValue === "" && selectedPlantTypes.length === 0)
      setPlants(plantList);

    const searchFilter = (plantName) => {
      if (searchValue === "") return true;

      return plantName.toLowerCase().includes(searchValue.toLowerCase());
    };

    const plantTypeFilter = (plantType) => {
      if (selectedPlantTypes.length === 0) return true;

      return selectedPlantTypes.includes(plantType);
    };

    setPlants(
      plantList.filter(
        (p) => searchFilter(p.plant) && plantTypeFilter(p.plantType.name)
      )
    );
  }, [plantList, searchValue, selectedPlantTypes]);

  const handleOnSearchValueChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const handleSelectedPlantTypesChange = ({ selectedItems }) => {
    setSelectedPlantTypes(selectedItems);
  };

  return (
    <>
      <Search
        aria-label="external set value"
        id="search-external"
        placeholder="Search plants"
        onChange={handleOnSearchValueChange}
        value={searchValue}
      />
      <br />
      <MultiSelect
        label="Filter plant types"
        items={plantTypeVariants}
        selectedOptions={selectedPlantTypes}
        handleSelectedItemsChange={handleSelectedPlantTypesChange}
      />
      <br />
      <Typography
        variant="body_short"
        bold
      >{`${plants.length} of ${plantList.length} shown`}</Typography>
      <List embedded={embedded}>
        {plants.map((data, index) => {
          return (
            <Link key={`content_item_${index}`} to={"/" + data.id}>
              <ListItem>
                <ContentDivider>
                  <Typography variant="body_short" bold>
                    {data.plant}
                  </Typography>
                  <Chip variant="active">{data.plantType.name}</Chip>
                </ContentDivider>
              </ListItem>
            </Link>
          );
        })}
      </List>
    </>
  );
};
export default PlantList;
