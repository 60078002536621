import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import {
  Button,
  TopBar,
  Tooltip,
  Icon,
  SideSheet,
} from "@equinor/eds-core-react";
import { grid_on, platform } from "@equinor/eds-icons";
import PlantList from "./PlantList";

Icon.add({ grid_on, platform });

const Icons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  > * {
    margin-left: 40px;
  }
`;

const RootLink = styled(Link)`
  text-decoration: none;

  &:hover,
  &:visited,
  &:active {
    color: black;
    text-decoration: none;
  }

  &:hover {
    color: gray;
  }
`;

const TopNav = ({ toggleSideSheet }) => {
  return (
    <TopBar>
      <TopBar.Header>
        <Icon name="grid_on" size={16} />
        <RootLink to="/">Z-015 Interface hook-up data sheets</RootLink>
      </TopBar.Header>
      <TopBar.Actions>
        <Icons>
          <Tooltip enterDelay={100} placement="bottom" title="Select plant">
            <Button variant="ghost_icon" onClick={toggleSideSheet}>
              <Icon name="platform" size={24} title="Select plant" />
            </Button>
          </Tooltip>
        </Icons>
      </TopBar.Actions>
    </TopBar>
  );
};

const SelectSheet = ({ toggleSideSheet, showSideSheet, plantList }) => {
  return (
    <SideSheet
      onClose={toggleSideSheet}
      style={{
        top: 64,
        right: 0,
        height: "calc(100vh - 64px)",
        position: "fixed",
      }}
      open={showSideSheet}
      title="Select plant"
      variant="xlarge"
    >
      <PlantList embedded plantList={plantList} />
    </SideSheet>
  );
};

const Layout = ({ children, plantList }) => {
  const [state, setState] = useState({ showSideSheet: false });

  const showSideSheet = state.showSideSheet;
  const toggleSideSheet = () => setState({ showSideSheet: !showSideSheet });

  return (
    <div style={{ minHeight: "100vh" }}>
      <div className="no-print">
        <TopNav toggleSideSheet={toggleSideSheet} />
        <SelectSheet
          plantList={plantList}
          toggleSideSheet={toggleSideSheet}
          showSideSheet={showSideSheet}
        />
      </div>
      <div style={{ margin: `3rem auto`, maxWidth: 1350, padding: `0 1rem` }}>
        {children}
      </div>
    </div>
  );
};

export default Layout;
